<template>
  <div
    class="atc-form-wrapper"
    :class="{ 'atc-form-is-tablet': isTablet, 'quick-shop': isQuickShop }"
  >
    <atc-form-close-button
      v-if="isFullScreenOnMobile"
      @click="$emit('close')"
    ></atc-form-close-button>
    <div
      :class="[
        'add-to-cart-form',
        { isManucurist },
        {
          'atc-full-screen':
            (isFullScreen || isBubbleAndLargeScreen) && !isQuickShop,
        },
        { 'is-desktop-form': isDesktop && !isHorizontalSwipe },
      ]"
    >
      <!-- not for 1st release  -->
      <Arrow v-if="false" class="arrow-close-atc" @click="$emit('close')" />
      <div
        class="form-container"
        :class="{
          'mobile-form-container':
            isMobile || (isFullScreen && isHorizontalSwipe),
          'inline-form-container': !isFullScreen || isQuickShop,
          'form-container-full-screen-horizontal':
            isFullScreen && isHorizontalSwipe,
        }"
      >
        <div
          v-if="
            cta.products.length > 1 &&
            isDesktop &&
            isFullScreen &&
            !isHorizontalSwipe &&
            !isQuickShop
          "
          class="back-button-wrapper"
        >
          <button class="back-button" @click="$emit('back')">
            <ArrowBack></ArrowBack>
            {{ $t("player.atcForm.back") }}
          </button>
        </div>
        <div class="flex-container">
          <div
            class="image-container"
            :class="{
              'image-container-bubble-inline-landscape':
                isBubble && !isFullScreen && (isLandscape || isSquare),
            }"
          >
            <img
              v-if="!isFullScreen || isQuickShop"
              class="variant-image"
              :src="resizedImageUrl || imageSource"
              :alt="product.name"
            />
            <image-carousel
              v-else
              :images="
                activeVariant.imageUrls || [activeVariant.imageUrl] ||
                product.imageUrls
              "
            ></image-carousel>
          </div>
          <div class="description">
            <div class="name">
              {{
                product.name.length > 24
                  ? decodeText(product.name.substring(0, 24) + "...")
                  : decodeText(product.name)
              }}
            </div>
            <div class="flex">
              <div class="price">
                {{ displayCurrency(currency, activeVariant?.price) }}
              </div>
              <div class="quantity">
                <h3 v-if="isOutOfStock" style="color: #e73d3d">
                  {{ $t("player.atcForm.outOfStock") }}
                </h3>

                <h3 v-else style="color: #01b64d">
                  {{ $t("player.atcForm.available") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="product.variants.length > 1"
          class="variants-container"
          :class="{ 'variants-container-desktop': isDesktop }"
        >
          <div
            class="variants"
            :class="{ 'variants-desktop': isDesktop }"
            v-for="{ name, values } in product.options"
            :key="name"
          >
            <label
              class="dropdown-labels"
              :class="{ 'inline-dropdown-label': !isFullScreen || isQuickShop }"
              :for="name"
              >{{ decodeText(name) }}</label
            >
            <select
              v-model="selectedValues[name]"
              :id="name"
              class="native-dropdown"
              :class="{ 'inline-dropdown': !isFullScreen || isQuickShop }"
              @change="selectOption(name)"
            >
              <option v-for="value in values" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </div>
        </div>
        <manucurist-picker
          v-if="isManucurist && Object.keys(manucuristSingleProduct).length > 0"
          :manucurist-product="manucuristSingleProduct"
          @selected-color="saveSelectedColors"
        ></manucurist-picker>
        <div v-if="showDescription" class="description-wrapper">
          <h1 class="description-title">Description</h1>
          <p class="description-text" v-html="product.description"></p>
        </div>
      </div>
      <div
        class="add-btn"
        :class="{
          'btn-is-inline': !isFullScreen || (isFullScreen && isHorizontalSwipe),
        }"
        v-tooltip="{
          text: tooltipContent,
          shouldShowTooltip: tooltipContent.length > 0,
        }"
      >
        <atc-form-secondary-button
          v-if="
            product.productUrl &&
            !isSameProductAsProductPage &&
            !isManucurist &&
            !isQuickShop
          "
          :colors="{
            primaryColor: format?.cta?.buttonColor || '#000000',
            secondaryColor: format?.cta?.textColor || '#000000',
          }"
          :isFullScreen="isFullScreen"
          @go-to-product-page="productPageRedirectionClicked"
        >
        </atc-form-secondary-button>
        <atc-form-add-button
          v-if="product.productId"
          v-tooltip="{
            text: $t('player.productAdded'),
            trigger: 'click',
            position: 'topClose',
          }"
          :variantId="activeVariant.id"
          :pluginProductId="product.productId"
          :vidjetProductId="product._id"
          :colors="buttonColors"
          :isOutOfStock="isOutOfStock || manucuristHasNotEnoughColors"
          :additionalData="isManucurist ? manucuristSavedColors : {}"
          :isManucurist="isManucurist"
          :isFullScreen="isFullScreen"
          :isQuickShop="isQuickShop"
          @added-to-cart="addedToCart"
          @buy-now-clicked="buyNowClicked"
        ></atc-form-add-button>
      </div>
    </div>
  </div>
</template>

<script>
import AtcFormAddButton from "./AtcFormAddButton";
import AtcFormSecondaryButton from "./AtcFormSecondaryButton";
import AtcFormCloseButton from "./AtcFormCloseButton";
import Arrow from "@/assets/arrow.svg";
import ManucuristPicker from "@/custom-features/manucurist/ManucuristPicker.vue";
import manucuristProductData from "@/custom-features/manucurist/product-data.json";
import ArrowBack from "@/assets/arrow-back.svg";
import { displayCurrency } from "../../utils/utils";
import ImageCarousel from "../additional-components/ImageCarousel.vue";
import { decodeText } from "@/utils/utils.js";

import { mapGetters, mapMutations, mapActions } from "vuex";
import { resizeImage } from "@/utils/resizeImage";

export default {
  name: "AddToCartForm",

  components: {
    AtcFormAddButton,
    AtcFormCloseButton,

    Arrow,
    ManucuristPicker,
    ArrowBack,
    AtcFormSecondaryButton,
    ImageCarousel,
  },

  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    videoIndex: {
      type: Number,
      default: 0,
    },

    isManucurist: {
      type: Boolean,
      default: false,
    },
    isQuickShop: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedValues: {},
      activeVariant: {},
      productId: null,
      manucuristSavedColors: {},
      manucuristProductData,
      displayCurrency,
      decodeText,
      resizedImageUrl: "",
    };
  },
  watch: {
    product: {
      handler(newValue) {
        if (this.productId !== newValue._id) {
          this.initializeAtcForm();
        }
      },
      deep: true,
    },
    imageSource: {
      handler(newUrl) {
        if (newUrl) this.updateImage(newUrl);
      },
      immediate: true,
    },
  },
  created() {
    // selected values by default take the 1st value of options array
    this.initializeAtcForm();
  },

  computed: {
    ...mapGetters("playerSite", ["currency", "integration"]),
    ...mapGetters("playerCampaign", [
      "isFullScreen",
      "isHorizontalSwipe",
      "isBubble",
      "isPopup",
      "isEmbed",
      "format",
    ]),
    ...mapGetters("videos", ["getCtaByIndex", "isLandscape", "isSquare"]),
    ...mapGetters("player", ["isMobile", "isInPreview", "isTablet"]),
    ...mapGetters("analytics", ["getPageProductId"]),

    isOutOfStock() {
      return (
        this.activeVariant?.qty === 0 && !this.activeVariant.sellWithoutStock
      );
    },

    cta() {
      return this.getCtaByIndex(this.videoIndex);
    },

    tooltipContent() {
      if (this.isOutOfStock) {
        return this.$t("player.atcForm.outOfStock");
      } else if (this.manucuristHasNotEnoughColors) {
        return this.$t("player.atcForm.selectMoreColors");
      } else {
        return "";
      }
    },

    hasProducts() {
      if (this.product.variants) {
        return this.product?.variants.length > 1;
      }
      return false;
    },

    manucuristHasNotEnoughColors() {
      // check if manucuristSingleProduct is not empty
      if (Object.keys(this.manucuristSingleProduct).length === 0) {
        return false;
      }
      const colorObject =
        this.manucuristSavedColors?.properties?.properties ?? {};
      return (
        this.isManucurist &&
        Object.keys(colorObject).length !==
          this.manucuristSingleProduct.colorsToSelect
      );
    },

    manucuristSingleProduct() {
      if (
        this.product.siteId === "81b984f5-5bb6-437b-9c00-50f028c40076" ||
        this.product.siteId === "39f362e5-c3f4-4eba-bdfd-2b9be89f827d" ||
        // test player site id
        this.product.siteId === "874f71d2-a54d-4830-9941-f4e30c237560"
      ) {
        return (
          manucuristProductData.find(
            (el) => el.productId === this.product._id
          ) ?? {}
        );
      }
      return {};
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet;
    },
    isFullScreenOnMobile() {
      return (
        (this.isFullScreen && this.isMobile) ||
        !this.isFullScreen ||
        this.isHorizontalSwipe ||
        this.isQuickShop
      );
    },
    isBubbleAndLargeScreen() {
      return (
        (this.isBubble || this.isEmbed) &&
        !this.isInPreview &&
        window.innerWidth >= 400
      );
    },
    buttonColors() {
      if (this.isQuickShop) {
        return {
          primaryColor:
            this.format?.quickShop?.buttonBackgroundColor || "#000000",
          secondaryColor: this.format?.quickShop?.buttonTextColor || "#ffffff",
        };
      } else {
        return {
          primaryColor: this.format?.cta?.buttonColor || this.cta.buttonColor,
          secondaryColor: this.format?.cta?.textColor || this.cta.textColor,
        };
      }
    },

    isSameProductAsProductPage() {
      const productId = this.product.productKey || this.product.productId;

      return (
        productId.toString() === this.getPageProductId ||
        parseInt(productId) === this.getPageProductId
      );
    },
    imageSource() {
      return (
        (this.activeVariant?.imageUrls && this.activeVariant?.imageUrls[0]) ||
        this.activeVariant?.imageUrl ||
        this.product.imageUrl
      );
    },
    showDescription() {
      if (!this.format.hasProductDescription) {
        return false;
      }
      if (this.isDesktop) {
        return (
          this.isFullScreen &&
          !this.isHorizontalSwipe &&
          this.product.description &&
          !this.isQuickShop
        );
      } else {
        return (
          this.isFullScreen &&
          !this.isHorizontalSwipe &&
          this.product.description &&
          !this.isQuickShop
        );
      }
    },
  },

  methods: {
    ...mapMutations({
      setBuyNowProduct: "analytics/SET_BUY_NOW_PRODUCT",
    }),

    ...mapActions({
      sendEvent: "analytics/sendEvent",
    }),

    initializeAtcForm() {
      this.selectedValues = {};
      this.product.options.forEach((option) => {
        this.selectedValues[option.name] = option.values[0];
      });
      this.productId = this.product._id;
      this.findVariant();
    },

    saveSelectedColors(payload) {
      this.manucuristSavedColors = payload;
    },

    selectOption(value, name) {
      this.selectedValues[name] = value;
      this.findVariant();
    },
    findOptionPosition(name) {
      return this.product.options.find((option) => {
        return option.name === name;
      })?.position;
    },

    // dynamically check if all selected values are all included in one product name
    findVariant() {
      let variantName = [];
      // find option name and position it in the right order
      Object.keys(this.selectedValues).forEach((key) => {
        variantName[this.findOptionPosition(key) - 1] =
          this.selectedValues[key];
      });
      // in shopify data, variant name is position 1 / position 2 / position 3
      variantName = variantName.join(" / ");
      this.activeVariant =
        this.product.variants.find((variant) => {
          return variant.name === variantName;
        }) || this.product.variants[0];
    },
    // can be moved to btn directly
    addedToCart(vidjetProductId) {
      this.$emit("added-to-cart", vidjetProductId);
      if (!this.isInPreview) {
        this.sendEvent({
          type: "addToCartBtnClick",
          productId: vidjetProductId,
        });
      }
    },
    buyNowClicked(payload) {
      if (!this.isInPreview) {
        this.sendEvent({
          type: "buyNowClicked",
          productId: payload.vidjetProductId,
        });
      }

      //We only want to get the cart data before creating the checkout through API for Shopify
      if (this.integration === "shopify") {
        const dataToSend = {
          messageOrigin: "vidjet",
          origin: "getCartItems",
        };

        delete payload.vidjetProductId;
        this.setBuyNowProduct(payload);
        window.parent.postMessage(dataToSend, "*");
      }
      if (this.isQuickShop) {
        this.$emit("buy-now-clicked");
      }
    },
    productPageRedirectionClicked(payload) {
      if (!this.isInPreview) {
        this.sendEvent({
          type: "productPageRedirectionClicked",
          productId: payload,
        });
      }
      const url = this.product.productUrl;
      this.isInPreview
        ? window.open(url, "_blank").focus()
        : (window.top.location.href = url);
    },
    async updateImage(url) {
      try {
        this.resizedImageUrl = await resizeImage(url);
      } catch (error) {
        console.error("Image resizing failed", error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/custom-features/manucurist/manucurist.scss";
.description-text {
  font-size: 14px;
  font-weight: 400;

  p,
  span,
  a,
  strong,
  em,
  b,
  ul,
  ol,
  li {
    line-height: 20px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    color: #007bff;
    text-decoration: underline;
    &:hover {
      color: #0056b3;
    }
  }
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    list-style-position: outside;
  }

  ol {
    list-style-type: decimal;
    padding-left: 20px;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;
  }

  h2 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.4rem;
  }

  h3 {
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 2.1rem;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.8rem;
  }

  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
  }

  b {
    font-weight: bold;
    color: #333;
    font-size: 1rem;
    line-height: 1.5;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 0.75rem;
    border: 1px solid #ddd;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }
  br {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    content: "";
  }
}
</style>

<style lang="scss" scoped>
.atc-form-wrapper {
  width: 100%;
  z-index: 5;
  margin: 0 auto;
}

.atc-form-is-tablet {
  width: 70%;
  margin: 0 auto;
}

.add-to-cart-form {
  width: 100%;
  padding: 16px;
  padding-bottom: 8px;
  box-sizing: border-box;
  max-height: 100%;
  position: relative;
  align-self: end;
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  border-radius: 5px;
  margin: 0 auto;
}

.is-desktop-form {
  filter: none;
}

.form-container {
  display: flex;
  max-height: 400px;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
}

.form-container::-webkit-scrollbar {
  width: 0;
}

.form-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.form-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}

// not for the first release
.arrow-close-atc {
  transform: rotate(180deg) scale(2);
  margin: 16px auto 0 auto;
  fill: #585858;
  cursor: pointer;
}

.flex-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.atc-full-screen.isMobile {
  .flex-container {
    width: 70%;
    margin: 0 auto;
  }
  .form-container {
    overflow: scroll;
  }
}

.image-container {
  height: 100px;
  width: 100px;
  display: grid;
  place-items: center;
}
.variant-image {
  border: 1px solid #000000;
  border-radius: 3px;
  display: block;
  max-height: 100px;
  max-width: 100px;
  width: auto;
  height: auto;
  margin: 0 auto;
  object-fit: cover;
  object-position: top;
}
.description {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin: 32px auto 16px;
}
.has-no-products {
  margin-bottom: 45px;
}
.name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isPortrait {
  .flex {
    align-items: initial;
    gap: 16px;
  }
}

.price {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #585858;
}
.variants-container {
  width: 100%;
}

.variants-container-desktop {
  margin-bottom: 0;
}

.variants {
  margin: 8px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  max-width: 100%;
  overflow: visible;
  position: relative;
}

.vidjet-single-select {
  width: 100%;
}

.native-dropdown {
  width: 100%;
  max-width: 100%;
  height: 34px;
  padding: 0 8px;
  font-size: 16px;
  font-weight: 500;
  color: black;
  border-radius: 2px;
  margin-right: 2px;
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateZ(0);
  z-index: 100;
}

@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 0) {
  .native-dropdown {
    font-size: 14px;
  }
}
.dropdown-labels {
  font-size: 16px;
  font-weight: 400;
  margin: auto 0;
}
.add-btn {
  z-index: 1000;
  margin: 16px 0 0;
  display: flex;
  gap: 8px;
  position: sticky;
  bottom: 8px;
  box-sizing: border-box;
}

.btn-is-inline {
  display: grid;
  bottom: unset;
}

.back-button-wrapper {
  color: $dark-grey;
  padding-bottom: 16px;
  .back-button {
    background: white;
  }
}

.form-container {
  .flex-container {
    margin-bottom: 0;
  }
  .image-container {
    grid-area: 1 / 1 / 2 / 2;
  }
  .variants-container {
    grid-area: 2 / 1 / 3 / 3;
    width: 100%;
  }
  .description {
    margin-bottom: 0;
  }
}
.variants {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.is-in-preview {
  .variants {
    grid-template-columns: 1fr 1fr;
  }
}

.is-in-preview.isPopup,
.is-in-preview.isBubble .add-to-cart-form {
  .vs__selected-options {
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: nowrap;
    max-width: max-content;
  }
}

.is-in-preview {
  &.isBubble {
    .image-container {
      height: 75px;
      width: 75px;
    }
    .variant-image {
      max-width: 75px;
      max-height: 75px;
    }
  }
  .image-container {
    height: 100px;
    width: 100px;
  }
  .variant-image {
    max-width: 100px;
    max-height: 100px;
  }
}
.atc-full-screen {
  min-height: calc(var(--vh, 1vh) * 100);
  .form-container {
    max-height: none;
    overflow: scroll;
  }

  &.add-to-cart-form {
    min-height: 100%;
    justify-content: initial;
  }

  .image-container {
    aspect-ratio: 1;
    width: 100%;
    height: auto;
  }
  .add-btn {
    color: #ffffff;
  }
}
.isMobile {
  .atc-full-screen {
    &.add-to-cart-form {
      overflow: auto;
    }
    .image-container {
      width: 100% !important;
    }
  }
}
.atc-form-wrapper .add-to-cart-form .form-container.inline-form-container {
  .flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding-top: 12px;
  }
  .image-container {
    place-items: flex-start;
    height: unset;
  }
  .variant-image {
    height: 60px;
    width: 60px;
  }
  .description {
    margin-top: 0;
    gap: 8px;
  }
  .name {
    font-size: 14px;
  }
  .quantity {
    font-size: 12px;
  }
  .price {
    font-size: 12px;
  }
}

.inline-dropdown-label {
  font-size: 14px;
}

.inline-dropdown {
  font-size: 14px;
  height: 30px;
}
.form-container-full-screen-horizontal {
  max-height: 97%;
}

.quick-shop {
  position: absolute;
  bottom: 4px;
  left: 2px;
  right: 2px;
  width: 98%;
}

.image-container-bubble-inline-landscape {
  width: 100px !important;
}

.description-wrapper {
  margin: 20px 0 24px;
  border-top: 2px solid #e7e7e7;
  padding-top: 28px;
}

.description-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
}
</style>
